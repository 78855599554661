<template>
    <div style="background-color: #171726;min-height: 100vh;">
        <div class="cash_title" style="background-color: #171726;">
            <img class="cash_back" src="img/backw.png" alt="" @click="closePage">
            <span style="color: #ffffff;">收益报表</span>
        </div>
        <div style="margin-top:10px">
            <van-tabs v-model="active1" type="line" background="#171726" title-inactive-color="#ffffff"
                title-active-color="#59DCFF" @change="onClickTab">
                <van-tab title="礼物"></van-tab>
                <van-tab title="相册"></van-tab>
                <van-tab title="开黑"></van-tab>
            </van-tabs>

        </div>
        <!-- 礼物收益报表 -->
        <div style="margin-top:20px;" v-if="active1 == 0 || active1 == 1">
            <div class="father_box" v-if="active1 == 0">
                <div :class="item.index == index_ ? 'child_tabs_click' : 'child_tabs'" v-for="(item, index) in tabs"
                    :key="index" @click="tabClick(item.title, item.index)">{{ item.title }}</div>
            </div>

            <!-- <van-tabs v-model="active2" type="card" @change="tabClick" swipeable animated>
                <van-tab :name="item.name" :title="item.title" v-for="(item, index) in tabs" :key="index"> -->
            <div class="noData" v-if="list.length == 0">
                <img src="img/noData.png" alt="">
            </div>
            <template v-if="list.length > 0">
                <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh"> -->
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getGiftList()" offset="10"
                    v-if="list.length > 0">
                    <!-- <van-cell-group v-for="(item, index) in list" :key="index" inset> -->
                    <div style="margin-top: 5px;padding: 0 16px;" v-for="(item, index) in list" :key="index">
                        <div class="cell-bottom">
                            <div class="cell-bottom-top" style="background: rgba(255,255,255,0.04);">
                                <div class="cell-bottom-left-top">{{ item.created_at }}</div>
                                <div class="cell-bottom-left-bottom">{{ item.money_name }}</div>
                            </div>
                            <div style="flex:1;background: rgba(255,255,255,0.04);" class="cell-bottom-bottom">
                                <div class="cell-bottom-right-top">
                                    <img class="diamond_big" src="img/bi.png" alt="">
                                    <span style="color:#ffffff">{{ item.text_number }}</span>
                                </div>
                                <div class="cell-bottom-right-bottom">
                                    <span style="color:#ffc639">余额：</span>
                                    <span style="color:#ffc639">{{ item.balance }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- </van-cell-group> -->
                </van-list>
                <!-- </van-pull-refresh> -->
            </template>
            <!-- </van-tab>
            </van-tabs> -->
        </div>
        <!-- 相册收益报表 -->
        <!-- <div style="margin-top: 20px;" v-if="active1 == 1">
            <van-empty description="暂无数据" v-if="list.length == 0" />
            <template v-if="list.length > 0">
        
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" v-if="list.length > 0">
                    <van-cell-group v-for="(item, index) in list" :key="index" inset>
                        <div style="background: #171726;margin-top: 10px;">
                            <div class="cell-bottom">
                                <div class="cell-bottom-top">
                                    <div class="cell-bottom-left-top">{{ item.created_at }}</div>
                                    <div class="cell-bottom-left-bottom">{{ item.money_name }}</div>
                                </div>
                                <div style="flex:1" class="cell-bottom-bottom">
                                    <div class="cell-bottom-right-top">
                                        <img class="diamond_big" src="img/money_icon.png" alt="">
                                        <span style="color:#ffffff">{{ item.text_number }}</span>
                                    </div>
                                    <div class="cell-bottom-right-bottom">
                                        <span>余额：</span>
                                        <span>{{ item.balance }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </van-cell-group>
                </van-list>
  
            </template>
        </div> -->
        <!-- 开黑收益报表 -->
        <div style="margin-top: 20px;" v-if="active1 == 2">
            <!-- <div class="fixed_top"> -->
            <div class="cash_box display_flex justify-content_flex-justify align-items_center"
                :class="{ 'is_fixed': isFixed }">
                <div class="cash_total">
                    <span>汇总</span>
                    <p>{{ total_real_income }}</p>
                </div>
                <div class="cash_game display_flex align-items_center" @click="ListClick">
                    <p>{{ gameList[gameCur].game_name }}</p>
                    <img src="img/game/down.png" alt="">
                </div>
                <div class="cash_Glist" v-if="showList">
                    <div class="cash_sort" style="background-image: url(img/game/sort.png);"></div>
                    <div class="cash_Gitem" :class="{ 'game_cur': gameCur == index }" v-for="(item, index) in gameList"
                        :key="index" @click="gameSelect(index)">{{ item.game_name }}</div>
                </div>
            </div>
            <!-- </div> -->
            <div class="cash_list">
                <div class="noData" v-if="orderList.length == 0">
                    <img src="img/noData.png" alt="">
                </div>
                <!-- <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh"
                class="padding-bottom-xl padding-lr-sm" style="min-height: 300px;"> -->
                <van-list v-model="loading2" :finished="finished2" finished-text="没有更多了" @load="cashList"
                    v-if="orderList.length > 0">
                    <div class="cash_item" v-for="(item, index) in orderList" :key="index">
                        <div class="cash_time display_flex justify-content_flex-justify align-items_center">
                            <p>{{ status(item.state) }}</p>
                            <span style="color: rgba(255, 255, 255, .3);">{{ item.created_at }}</span>
                        </div>
                        <div class="cash_detail display_flex align-items_center">
                            <img :src="item.head_portrait" alt="">
                            <div class="cash_text margin-left">
                                <p>{{ item.nickname }}</p>
                                <div class="display_flex align-items_center margin-top-sm"
                                    style="color: rgba(255, 255, 255, .4);">
                                    <img :src="item.game_logo" alt="">
                                    <span class="margin-lr-xs">{{ item.game_name }}</span>
                                    <span class="margin-right-xs">{{ item.price }}</span>
                                    <img src="img/bi.png" alt="">
                                    <span>/局</span>
                                    <span class="margin-left-xs">x{{ item.num }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="cash_money display_flex justify-content_flex-justify align-items_center">
                            <div class="display_flex align-items_center">
                                <span>已支付</span>
                                <p class="margin-lr-xs">{{ item.total_price }}</p>
                                <img src="img/bi.png" alt="">
                            </div>
                            <div class="display_flex align-items_center">
                                <span>收益(元):</span>
                                <div class="cash_count margin-left-xs ">{{ item.real_income }}</div>
                            </div>
                        </div>
                    </div>
                </van-list>
                <!-- <div class="noData margin-top-xl" v-if="orderList.length == 0">
                        <img src="img/noData.png" alt="">
                        <p class="margin-top">暂无数据</p>
                    </div> -->
                <!-- </van-pull-refresh> -->
            </div>
        </div>
    </div>
</template>
<script>
import { incomeRecord, gameList, orderEarnings } from "@/api/api";
import "@/assets/css/base.css";
import "@/assets/css/game.css";
import NavBar from '@/components/navBar.vue';
export default {
    components: {
        NavBar
    },
    data() {
        return {
            tabs: [{ title: '房间', index: 0 }, { title: '糖果乐园', index: 2 }, { title: '私聊', index: 3 },{ title: '星际旅行', index: 4 }],
            active1: 0,
            index_: 0,
            list: [],
            loading: false,
            finished: false,
            loading2: false,
            finished2: false,
            // refreshing: false,
            type: 1,
            id: '0',
            isFixed: '',
            showList: false,
            from:'',
            gameCur: 0,
            gameList: [
                {
                    game_name: '全部',
                    type: 3
                }
            ],
            orderList: [],
            total_real_income: 0,
            row: 10,
            page: 1

        };
    },
    computed: {
        // 订单状态1待确认  2已取消  3已拒绝  4进行中 5已退款 6退款中 7已完成
        status() {
            return function (e) {
                if (e == 1) {
                    return '待确认'
                } else if (e == 2) {
                    return '已取消'
                } else if (e == 3) {
                    return '已拒绝'
                } else if (e == 4) {
                    return '已拒绝'
                } else if (e == 5) {
                    return '进行中'
                } else if (e == 6) {
                    return '进行中'
                } else if (e == 7) {
                    return '进行中'
                } else if (e == 8) {
                    return '进行中'
                } else if (e == 9) {
                    return '已退款'
                } else if (e == 10) {
                    return '已退款'
                } else if (e == 11) {
                    return '退款中'
                } else if (e == 12) {
                    return '已完成'
                } else if (e == 13) {
                    return '已完成'
                }
            }
        }
    },
    // mounted() {

    //     window.addEventListener('scroll', this.Scrollbottom);  //页面加载时监听滚动
    // },
    // destroyed() {
    //     window.removeEventListener('scroll', this.Scrollbottom) //页面离开后销毁监听事件
    // },
    created() {
        let access_token = this.$route.query.access_token
        if(this.$route.query.from){
            this.from = this.$route.query.from
        }
        localStorage.setItem('access_token', access_token)
        this.getGiftList()
        this.getGameList()
        // this.cashList()
    },
    methods: {
        onClickTab(i) {
            this.active1 = i
            this.finished = false
            this.finished2 = false
            if (i == 0) {
                this.id = '0'
                this.list = []
                this.type = 1
                this.getGiftList()
            }
            if (i == 1) {
                this.id = '0'
                this.list = []
                this.type = 7
                this.getGiftList()
            }
            if (i == 2) {
                this.page = 1
                this.orderList = []
                this.cashList()
            }
        },
        tabClick(title, index) {
            this.list = []
            this.id = '0'
            this.index_ = index
            this.finished = false
            if (title == '房间') {
                this.type = 1
                this.getGiftList()
            } else if (title == '幸运') {
                this.type = 4
                this.getGiftList()
            } else if (title == '糖果乐园') {
                this.type = 3
                this.getGiftList()
            } else if(title=='私聊'){
                this.type = 5
                this.getGiftList()
            }else{
                this.type = 12
                this.getGiftList()
            }
        },
        closePage() {
            if(this.from=='h5'){
                this.$router.go(-1)
            }else{
                let params = '调用'
            this.$bridge.callhandler('closePage', params, (data) => {
                // 处理返回数据
            })
            }
          
        },
        // onRefresh() {
        //     console.log('刷新了')
        //     if (this.active1 == 0 || this.active1 == 1) {
        //         this.list = []
        //         this.getGiftList()
        //     }
        //     if (this.active1 == 2) {
        //         this.orderList = []
        //         this.page = 1
        //         this.cashList()
        //     }
        //     this.finished = false
        //     this.loading = true;

        // },
        getGiftList() {
            let params = {
                type: this.type,
                rows: 10,
                log_id: this.id
            }
            // this.loading = true
            incomeRecord(params).then(res => {
                this.loading = false
                if (this.id == '0') {
                    this.list = res.data
                } else {
                    this.list = this.list.concat(res.data);
                }
                if (this.list.length > 0) {
                    this.id = this.list[this.list.length - 1].id
                }
                if (res.data.length < 10) {
                    this.finished = true;
                }
            },
                error => {
                    console.log(error)
                    this.$toast.fail(error)
                }).catch((error) => {
                    console.log(error)
                    this.$toast.fail(error)
                })


        },
        getGameList() {
            gameList({ game_type: 3 }).then(response => {
                this.gameList = this.gameList.concat(response.data)
                console.log(this.gameList)
            },
                error => {
                    this.$toast.fail(error)
                }).catch((error) => {
                    this.$toast.fail(error)
                })
        },
        cashList() {
            // if (this.refreshing) {
            //     this.orderList = [];
            //     this.page = 1
            //     this.refreshing = false;
            // }
            if (this.gameCur == 0) {
                var param = {
                    page: this.page,
                    rows: this.row
                }
            } else {
                var param = {
                    page: this.page,
                    rows: this.row,
                    game_id: this.gameList[this.gameCur].id
                }
            }

            orderEarnings(param).then(response => {
                this.orderList = this.orderList.concat(response.data.order_list);
                this.total_real_income = response.data.total_real_income
                this.loading2 = false;
                if (response.data.order_list.length < 10) {
                    this.finished2 = true;
                }
                this.page = this.page + 1
            },
                error => {
                    this.$toast.fail(error)
                }).catch((error) => {
                    this.$toast.fail(error)
                })
        },
        ListClick() {
            let state = this.showList === true ? false : true;
            this.showList = state;
        },
        gameSelect(e) {
            this.gameCur = e
            this.showList = false
            this.orderList = [];
            this.page = 1
            this.loading2 = true;
            this.finished2 = false;
            this.cashList();
        },
    }
};
</script>
<style scoped>
.list_box {
    max-width: 100%;
    padding: 0 30px 0 30px;
}

.table_tip {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    font-size: 28px;
}

.table {
    width: 100%;
}

.top_table,
.bottom_table {
    width: 100%;
    display: flex;
    height: 160px;
}

.table_item {
    flex: 1;
}

.table_title {
    width: 100%;
    height: 80px;
    text-align: center;
    line-height: 80px;
    background-color: #f5f7f9;
}

.table_value {
    width: 100%;
    height: 80px;
    text-align: center;
    line-height: 80px;
    background-color: #ffffff;
}

.panel-footer {
    font-size: 26px;
    text-align: right;
}

.cell-bottom {
    display: flex;
    width: 100%;
    height: 150px;
    /* background: #20202f; */
}

.cell-bottom-top {
    flex: 1;
    padding: 0 30px 0 30px;
    background: rgba(255, 255, 255, .15);
}

.diamond {
    width: 34px;
    height: 30px;
}

.diamond_big {
    width: 38px;
    height: 34px;
}

.cell-bottom-bottom {
    padding: 0 30px 0 30px;
    background: rgba(255, 255, 255, .15);
    ;
}

.cell-bottom-right-top {

    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
}

.cell-bottom-right-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    color: #ffffff;
}

.cell-bottom-left-top {
    margin-top: 30px;
    color: #ffffff;
}

.cell-bottom-left-bottom {
    margin-top: 30px;
    font-weight: 700;
    color: #ffffff;
}
</style>